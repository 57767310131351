//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import {mapState} from "vuex";

export default Vue.extend({
  name: 'LiveWrappedAd',
  props: {
    id: String,
    lwID: String,
    adLabel: {
      type: String,
      default: null,
      required: false,
    },
    customClass: {
      type: String,
      default: '',
      required: false,
    },
  },
  methods: {
    liveWrappedAllowedDevices(adPlacementID) {
      let allowedDevices = ['desktop', 'mobil'];
      if (adPlacementID.includes('-desktop')) {
        allowedDevices = ['desktop'];
      } else if (adPlacementID.includes('-mobil')) {
        allowedDevices = ['mobil'];
      }
      return allowedDevices;
    },
    adUnitRender() {
      window.lwhb.cmd.push(() => {
        this.liveWrappedAllowedDevices(this.liveWrappedID).forEach(device => {
          window.lwhb.loadAd({
            tagId: this.liveWrappedID,
            adUnitName: this.liveWrappedID.replace('dn_', `dn_${device}-`).replace('-desktop', '').replace('-mobil', ''),
            ignoreVisibility: true
          });
        })
      });
    }
  },

  computed: {
    ...mapState(['placeholdersAds']),
    liveWrappedID () {
      if(this.lwID) {
        return this.lwID;
      } else if(this.placeholdersAds[this.id] && this.placeholdersAds[this.id].lwID) {
        return this.placeholdersAds[this.id].lwID;
      } else {
        return this.id;
      }
    }
  },

  mounted() {
    this.adUnitRender();
  },

  errorCaptured: function (err) {
    console.error(`Caught error from children of DfpAd component:: ${err.message}`);
  },
});
